import { jsx as _jsx } from "react/jsx-runtime";
import DeVpn from '../assets/projects/de-vpn.png';
import DeSim from '../assets/projects/de-sim.png';
import DeCoupons from '../assets/projects/de-coupons.png';
import DeWalletPreview1 from './preview/wallet/1.png';
import DeWalletPreview2 from './preview/wallet/2.png';
import DeWalletPreview3 from './preview/wallet/3.png';
import DeWalletPreview4 from './preview/wallet/4.png';
import DeVpnPreview1 from './preview/vpn/01.png';
import DeVpnPreview2 from './preview/vpn/02.png';
import DeVpnPreview3 from './preview/vpn/03.png';
import DeVpnPreview4 from './preview/vpn/04.png';
import DeSimPreview1 from './preview/sim/01.png';
import DeSimPreview2 from './preview/sim/02.png';
import DeSimPreview3 from './preview/sim/03.png';
import DeSimPreview4 from './preview/sim/04.png';
import DeCouponsPreview1 from './preview/coupons/01.png';
import DeCouponsPreview2 from './preview/coupons/02.png';
import DeCouponsPreview3 from './preview/coupons/03.png';
import DeCouponsPreview4 from './preview/coupons/04.png';
import { SvgSelector } from '../assets/svg-selector';
export const projectData = [
    {
        id: 1,
        img: _jsx("img", { src: DeVpn, alt: "de-vpn" }),
        icon: _jsx(SvgSelector, { id: "DeVpn-logo" }),
        title: 'DeVpn',
        subtitle: 'projects.de-vpn.title',
        link: 'https://t.me/delabtonbot/vpn',
        description: 'projects.de-vpn.description',
        detail_description: 'projects.de-vpn.detail_description',
        imageArray: [
            DeVpnPreview1,
            DeVpnPreview2,
            DeVpnPreview3,
            DeVpnPreview4
        ]
    },
    {
        id: 2,
        img: _jsx("img", { src: DeSim, alt: "de-sim" }),
        icon: _jsx(SvgSelector, { id: "DeSim-logo" }),
        title: 'DeSim',
        link: 'https://t.me/delabtonbot/esim',
        subtitle: 'projects.de-sim.title',
        description: 'projects.de-sim.description1',
        description2: 'projects.de-sim.description2',
        description3: 'projects.de-sim.description3',
        detail_description: 'projects.de-sim.detail_description',
        imageArray: [
            DeSimPreview1,
            DeSimPreview2,
            DeSimPreview3,
            DeSimPreview4
        ]
    },
    {
        id: 3,
        img: _jsx("img", { src: DeCoupons, alt: "de-coupons" }),
        icon: _jsx(SvgSelector, { id: "DeSim-logo" }),
        title: 'DeCoupons',
        link: 'https://t.me/delabtonbot/dewa',
        subtitle: 'projects.de-coupons.title',
        description: 'projects.de-coupons.description1',
        description2: 'projects.de-coupons.description2',
        detail_description: 'projects.de-coupons.detail_description',
        imageArray: [
            DeCouponsPreview1,
            DeCouponsPreview2,
            DeCouponsPreview3,
            DeCouponsPreview4
        ]
    },
    {
        id: 4,
        img: '',
        icon: null,
        title: 'DeWallet',
        link: 'https://t.me/delabtonbot',
        subtitle: 'projects.de-wallet.subtitle',
        description: 'projects.de-wallet.description',
        detail_description: 'projects.de-wallet.description',
        imageArray: [
            DeWalletPreview1,
            DeWalletPreview2,
            DeWalletPreview3,
            DeWalletPreview4
        ]
    }
];
