import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Container } from '../../components/container';
import { Button } from '../../components/ui/button';
import { projectData } from '../../mocks/projects';
import { useMediaQuery } from '../../hooks/use-media-query';
import { SvgSelector } from '../../assets/svg-selector';
import PreviewDelab from '../../mocks/preview/wallet/logo.jpg';
import 'swiper/css';
import 'swiper/css/pagination';
import 'react-image-lightbox/style.css';
import s from './detail.module.scss';
export const Detail = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    if (!id) {
        navigate('/');
    }
    const data = projectData.find(el => el.id === Number(id));
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const openLightbox = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };
    const sliderRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const isMobile = useMediaQuery(550);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (_jsx("div", { className: s.detailContent, children: _jsxs(Container, { children: [_jsxs("div", { className: s.detailContentTop, children: [_jsxs("div", { className: s.detailContentInfo, children: [_jsx("img", { src: PreviewDelab, alt: "logo" }), _jsxs("div", { children: [_jsx("span", { className: s.detailContentTitle, children: data?.title }), _jsx("p", { className: s.detailContentDescription, children: t(`${data?.subtitle}`) })] })] }), data && (_jsx(Link, { to: data?.link, target: "_blank", children: _jsxs(Button, { variant: "action", className: s.detailContentButton, children: [_jsx(SvgSelector, { id: "arrow-top-right" }), " ", t('common.open-app')] }) }))] }), _jsx("div", { className: s.inner, children: data?.imageArray && (_jsxs("div", { className: s.detailContentSlider, ref: sliderRef, children: [_jsx(Swiper, { modules: [Navigation], spaceBetween: 30, slidesPerView: 3, onActiveIndexChange: (index) => setActiveIndex(index.activeIndex), className: s.useCasesSwiper, direction: 'horizontal', navigation: {
                                    prevEl: '.prev-button',
                                    nextEl: '.next-button'
                                }, centeredSlides: !!isMobile, breakpoints: {
                                    200: { slidesPerView: 1 },
                                    380: { slidesPerView: 1.1, spaceBetween: 0 },
                                    420: { slidesPerView: 1.3, spaceBetween: 0 },
                                    550: { slidesPerView: 1.5 },
                                    600: { slidesPerView: 1.9 },
                                    700: { slidesPerView: 2.3 },
                                    800: { slidesPerView: 2.7 },
                                    900: { slidesPerView: 2.8 },
                                    1000: { slidesPerView: 2.9 },
                                    1100: { slidesPerView: 3.3 }
                                }, allowTouchMove: true, children: data?.imageArray.map((el, index) => (_jsx(SwiperSlide, { children: _jsx("img", { src: el, onClick: () => openLightbox(index), alt: `el-${index}`, className: s.sliderImg }) }, index))) }), _jsx("button", { className: `${s.chevronLeft} prev-button`, children: _jsx(SvgSelector, { id: "chevron-left2" }) }), _jsx("button", { className: `${s.chevronRight} next-button`, children: _jsx(SvgSelector, { id: "chevron-right2" }) })] })) }), isOpen && data?.imageArray && (_jsx(Lightbox, { mainSrc: data?.imageArray[photoIndex] || '', nextSrc: data?.imageArray[(photoIndex + 1) % data?.imageArray.length] || '', prevSrc: data?.imageArray[(photoIndex + data?.imageArray.length - 1) % data?.imageArray.length] || '', onCloseRequest: () => setIsOpen(false), onMovePrevRequest: () => setPhotoIndex((photoIndex + data?.imageArray?.length - 1)
                        % data?.imageArray.length), onMoveNextRequest: () => setPhotoIndex((photoIndex + 1) % data?.imageArray?.length) })), _jsxs("div", { className: s.detailContentBottom, children: [_jsx("div", { className: s.detailContentBottomTitle, children: t('common.description') }), _jsx("p", { children: t(`${data?.detail_description}`) })] })] }) }));
};
