export const footerProjects = [
    {
        link: 'https://t.me/delabtonbot',
        title: 'DeWallet'
    },
    {
        link: 'https://t.me/delabtonbot/vpn',
        title: 'DeVPN'
    },
    {
        link: 'https://t.me/delabtonbot/esim',
        title: 'DeSim'
    },
    {
        link: 'https://t.me/delabtonbot/dewa',
        title: 'DeCoupons'
    }
];
export const footerContacts = [
    {
        link: 'https://t.me/delab',
        title: 'Telegram'
    },
    {
        link: 'https://x.com/delabteam',
        title: 'Twitter'
    },
    {
        link: 'https://github.com/delab-team"',
        title: 'GitHub'
    },
    {
        link: 'mailto:delab.ton@gmail.com',
        title: 'Mail'
    }
];
export const footerCommunity = [
    {
        link: 'https://t.me/delab',
        title: 'footer.community.telegram-channel'
    },
    {
        link: 'https://t.me/delabchat',
        title: 'footer.community.telegram-chat'
    }
];
