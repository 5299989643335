import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Container } from '../../../components/container';
import { SvgSelector } from '../../../assets/svg-selector';
import Decor from '../../../assets/providing/decor.png';
import Dfc from '../../../assets/providing/dfc.png';
import s from './providing.module.scss';
export const providingRef = { current: null };
export const Providing = () => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: s.providing, id: "providing", children: [_jsxs(Container, { children: [_jsxs("div", { className: s.providingInfo, children: [_jsx(SvgSelector, { id: "logo" }), _jsxs("h2", { className: s.providingTitle, ref: providingRef, children: [t('providing.title1'), " ", '  ', " ", _jsxs("span", { children: [" ", t('providing.title2')] }), " ", '  ', " ", t('providing.title3')] }), _jsx("p", { className: s.providingDescription, children: t('providing.description') })] }), _jsxs("div", { className: s.providingSupported, children: [_jsxs("div", { className: s.providingSupportedInner, children: [_jsxs("h4", { children: [_jsx(SvgSelector, { id: "dfc-logo" }), t('providing.supported.title')] }), _jsx("p", { children: t('providing.supported.description1') }), _jsx("p", { children: t('providing.supported.description2') }), _jsx("p", { children: t('providing.supported.description3') })] }), _jsx("img", { src: Dfc, alt: "decor" })] })] }), _jsx("img", { className: s.providingDecor, src: Decor, alt: "decor" })] }));
};
